import React from 'react';
import Style from './Courses.module.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { AiOutlineHtml5 } from 'react-icons/ai';
import { IoLogoCss3, IoLogoJavascript } from 'react-icons/io';
import HtmlImg from '../Images/illustrations/html.webp';
import JavascriptImg from '../Images/illustrations/Javascript.webp';
import CssImg from '../Images/illustrations/css.webp';
import AdobeImg from '../Images/illustrations/adobe.webp';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import CourseData from './CourseData';
import { Fade } from 'react-reveal';

const CourseWebDesign = () => {
  const scrolltoTop = () => {
    window.scrollTo({ top: 330 });
  };

  return (
    <div className={Style.Courses_main}>
      <div className={Style.Courses_sidebar}>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/courses"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Diploma In Computer Applications(DCA)
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/tally-accounting-traning"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Tally Accounting Training
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/web-designing"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Web Designing
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/web-development"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Web Development
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/typing-english-punjabi"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Typing English & Punjabi
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/ms-office"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            MS-Office
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/auto-cad"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Auto-CAD
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/computer-basics/"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Basics
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/adobe-photoshop"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Adobe Photoshop
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/computer-programming"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Programming
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/adca"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Advance Dimploma in Computer Application(ADCA)
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/cbtd"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Basic and Tally with Data Entry
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/pgdca"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            PGDCA
          </Link>
        </div>
      </div>
      <hr />
      <Bounce right>
        <div className={Style.Courses_right}>
          <div className={Style.Upper_main}>
            <div className={Style.Upper_main_left}>
              <h1>What is Web Designing?</h1>
              <h2>
                Web design refers to the design of websites that are displayed
                on the internet. It usually refers to the user experience
                aspects of website development rather than software development.
                Web design used to be focused on designing websites for desktop
                browsers; however, since the mid-2010s, design for mobile and
                tablet browsers has become ever-increasingly important.
              </h2>

              <div className={Style.Contact_btn_main}>
                <a href="tel:+9815953964" className={Style.Anchor_tag}>
                  <span className={Style.Top_key}></span>
                  <span className={Style.Anchor_text}>Enquiry</span>
                  <span className={Style.Bottom_key_1}></span>
                  <span className={Style.Bottom_key_2}></span>
                </a>
              </div>
            </div>
            <div className={Style.Upper_main_right}>
              <img src={AdobeImg} alt="Web designing" />
            </div>
          </div>
          <div className={Style.Fees_Criteria}>
            <div className={Style.Criteria_table}>
              <div className={Style.Criteria_table_inner}>
                <h4>Course</h4>
                <p>Web Designing</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Fees</h4>
                <p>5999 /-</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Duration</h4>
                <p>6 Months</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Timing</h4>
                <p>Morning / Evening</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Topics</h4>
                <p>HTML 5, CSS 3, Java Script</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Enquiry</h4>
                <p>+919815953964</p>
              </div>
            </div>
          </div>

          <h2 className={Style.Topic_title}>Topics Covered In This Course</h2>
          <div className={Style.Topic_main_cont}>
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <AiOutlineHtml5 size={45} style={{ fill: '#5777ba' }} />
              </div>
              <div className={Style.Topic_main_right}>
                <div>
                  <h3>HTML 5</h3>
                  <p>
                    HTML stands for Hyper Text Markup Language. HTML is the
                    standard markup language for creating Web pages. HTML
                    describes the structure of a Web page. HTML consists of a
                    series of elements.
                  </p>
                </div>
              </div>
            </div>
            {/* second topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <IoLogoCss3 size={45} style={{ fill: '#5777ba' }} />
              </div>
              <div className={Style.Topic_main_right}>
                <div>
                  <h3>CSS</h3>
                  <p>
                    CSS is used to define styles for your web pages, including
                    the design, layout and variations in display for different
                    devices and screen sizes. CSS describes how elements should
                    be rendered on screen, on paper, in speech, or on other
                    media.
                  </p>
                </div>
              </div>
            </div>
            {/* third topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <IoLogoJavascript size={45} style={{ fill: '#5777ba' }} />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Java Script</h3>
                <p>
                  JavaScript is a dynamic programming language that's used for
                  web development, in web applications, for game development,
                  and lots more.
                </p>
              </div>
            </div>
          </div>

          {/* computer fundamentals */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={HtmlImg} alt="HTML 5" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>HTML 5</h1>
                <p>
                  HTML5 is an effort is to bring order to web development chaos
                  by organizing common practices, embracing implementations from
                  various browsers. It is massive, with over 100 specifications
                  as part of the HTML5 specs. Understanding this, you can
                  simplify by thinking of HTML5 this way. HTML5 is simply just
                  an umbrella term for the next generation of web apps an how
                  functionality will be expanded with better markup HTML, better
                  style CSS, and better interactivity JavaScript.
                </p>
              </div>
            </div>
          </Fade>
          {/* MS office */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>CSS</h1>
                <p>
                  CSS is designed to enable the separation of presentation and
                  content, including layout, colors, and fonts. This separation
                  can improve content accessibility; provide more flexibility
                  and control in the specification of presentation
                  characteristics; enable multiple web pages to share formatting
                  by specifying the relevant CSS in a separate .css file, which
                  reduces complexity and repetition in the structural content;
                  and enable the .css file to be cached to improve the page load
                  speed between the pages that share the file and its
                  formatting. Separation of formatting and content also makes it
                  feasible to present the same markup page in different styles
                  for different rendering methods, such as on-screen, in print,
                  by voice via speech-based browser or screen reader, and on
                  Braille-based tactile devices. CSS also has rules for
                  alternate formatting if the content is accessed on a mobile
                  device.
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={CssImg} alt="CSS 3" />
                </Slide>
              </div>
            </div>
          </Fade>
          {/* accounting package */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={JavascriptImg} alt="Java script" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>JavaScript</h1>
                <p>
                  JavaScript is a client-side scripting language, which means
                  the source code is processed by the client's web browser
                  rather than on the web server. This means JavaScript functions
                  can run after a webpage has loaded without communicating with
                  the server. For example, a JavaScript function may check a web
                  form before it is submitted to make sure all the required
                  fields have been filled out. The JavaScript code can produce
                  an error message before any information is actually
                  transmitted to the server. Like server-side scripting
                  languages, such as PHP and ASP, JavaScript code can be
                  inserted anywhere within the HTML of a webpage. However, only
                  the output of server-side code is displayed in the HTML, while
                  JavaScript code remains fully visible in the source of the
                  webpage. It can also be referenced in a separate .JS file,
                  which may also be viewed in a browser.
                </p>
              </div>
            </div>
          </Fade>

          {/* Relevant courses */}
          <h1 className={Style.Relevant_cont_title}>
            More Relevant Courses...
          </h1>
          <div className={Style.Relevant_courses_cont}>
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[0].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[0].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[0].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[0].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[0].description}
                </p>
                <button className={Style.Relevant_link}>
                  <Link
                    to="/tally-accounting-traning"
                    exact="true"
                    onClick={scrolltoTop}
                  >
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* second relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[1].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[1].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[1].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[1].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[1].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/adca" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* third relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[4].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[4].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[4].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[4].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[4].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/web-development" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* fourth relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[6].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[6].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[6].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[6].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[6].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/auto-cad" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
          </div>

          {/* Enspirational quotes  */}
          <div className={Style.Quotes_main_cont}>
            <div className={Style.Quote_card1}>
              <div className={Style.Quote_text1}>
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 330 307"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M302.258 176.221C320.678 176.221 329.889 185.432 329.889 203.853V278.764C329.889 297.185 320.678 306.395 302.258 306.395H231.031C212.61 306.395 203.399 297.185 203.399 278.764V203.853C203.399 160.871 207.902 123.415 216.908 91.4858C226.323 59.1472 244.539 30.902 271.556 6.75027C280.562 -1.02739 288.135 -2.05076 294.275 3.68014L321.906 29.4692C328.047 35.2001 326.614 42.1591 317.608 50.3461C303.69 62.6266 292.228 80.4334 283.223 103.766C274.626 126.69 270.328 150.842 270.328 176.221H302.258ZM99.629 176.221C118.05 176.221 127.26 185.432 127.26 203.853V278.764C127.26 297.185 118.05 306.395 99.629 306.395H28.402C9.98126 306.395 0.770874 297.185 0.770874 278.764V203.853C0.770874 160.871 5.27373 123.415 14.2794 91.4858C23.6945 59.1472 41.9106 30.902 68.9277 6.75027C77.9335 -1.02739 85.5064 -2.05076 91.6467 3.68014L119.278 29.4692C125.418 35.2001 123.985 42.1591 114.98 50.3461C101.062 62.6266 89.6 80.4334 80.5942 103.766C71.9979 126.69 67.6997 150.842 67.6997 176.221H99.629Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div className={Style.Quote_body_text1}>
                {' '}
                You can, you should, and if you are brave enough to start, you
                will.
              </div>
              <div className={Style.Quote_author1}>- by Stephen King</div>
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};

export default CourseWebDesign;
