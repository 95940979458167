import React from 'react';
import Style from './Home.module.css';
import { Link } from 'react-router-dom';

const CardComponent = (props) => {
  const scrolltoTop = () => {
    window.scrollTo({ top: 330 });
  };
  let { title, description, image, duration, fees, slug } = props;
  return (
    <div>
      <div className={Style.Card}>
        <div className={Style.Blob}></div>
        <div className={Style.Content}>
          <img src={image} alt={`${title} course`} className={Style.Img} />
          <p>
            <span className={Style.Description}>{description}</span>
          </p>
        </div>
        <div className={Style.CardTitle}>
          <h2>{title}</h2>
          <span>Read More...</span>
        </div>
        <div className={Style.CourseDetails}>
          Duration: {duration} <div></div> Fees: {fees} <div></div>
          <Link to={slug} exact="true" onClick={scrolltoTop}>
            Read more...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
