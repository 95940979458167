import { UserProvider } from './userContext';
import { CertificateProvider } from './certificateContext';

export function CombineProviders({ children }) {
  return (
    <>
      <UserProvider>
        <CertificateProvider>{children}</CertificateProvider>
      </UserProvider>
    </>
  );
}

export default CombineProviders;
