import React from 'react';
import Style from './Footer.module.css';
import { Link } from 'react-router-dom';
import GcLogo from '../Images/new logo.webp';

const Footer = () => {
  return (
    <div className={Style.MainCont}>
      <hr />
      <div className={Style.FooterMain}>
        <div className={Style.Left}>
          <img
            src={GcLogo}
            style={{ height: '8vw' }}
            alt="Gandhi computers logo"
          />
          <div className={Style.AddressText}>
            <span>Opp. SBI Bank, Gagneja Street, </span>
            <span>Jalalabad(w) 152024 , Fazilka</span>
            <span>Punjab 144001</span>
          </div>
          <div className={Style.AddressText}>
            <a href="tel:+919814353964" className={Style.Phone}>
              +91 9814353964
            </a>
            <a href="mailto:gandhiinstitute7@gmail.com">
              gandhiinstitute7@gmail.com
            </a>
          </div>
        </div>
        <div className={Style.Footer_Bottom_wraper}>
          <div className={Style.Center}>
            <span>Quick Links</span>

            <div>
              <p>
                <Link to="/">Home</Link>
              </p>
              <p>
                <Link to="/courses">Our Courses</Link>
              </p>
              <p>
                <Link to="/contact">Contact us</Link>
              </p>
              <p>
                <Link to="/about">About us</Link>
              </p>
              <p>
                <Link to="/login">Login</Link>
              </p>
            </div>
          </div>
          <div className={Style.Right}>
            <span>Our Popular Courses</span>
            <div>
              <p>
                <Link to="/">Tally Accounting Training</Link>
              </p>
              <p>
                <Link to="/">Web Designing & Web Development</Link>
              </p>
              <p>
                <Link to="/">Programming Courses</Link>
              </p>
              <p>
                <Link to="/">English & Punjabi Typing</Link>
              </p>
              <p>
                <Link to="/">MS Office </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.DeveloperArea}>
        <h4>Designed and Developed by : @Satnam Sandhu</h4>
        <a href="tel:+919371070002">Contact : 9371070002</a>
      </div>
    </div>
  );
};

export default Footer;
