import React, { useContext, useEffect, useState } from 'react';
import styles from './Login.module.css';
import login_svg from '../../Images/illustrations/login.svg';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/userContext';

const Login = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const { user, login } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.length === 0 || password.length === 0) return;
    login({ email, password });
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      navigate('/admin');
    }
  }, [user.isAuthenticated, navigate]);

  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <img src={login_svg} alt="Login" />
      </div>
      <div className={styles.right}>
        <h2>Login</h2>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div>
            <label htmlFor="email" className={styles.label}>
              Email Address
            </label>
            <input
              type="email"
              name="email"
              placeholder="yourname@example.com"
              value={email}
              required
              className={styles.input}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
          {/* password */}
          <div>
            <label htmlFor="password" className={styles.label}>
              Password
            </label>
            <input
              type="password"
              name="password"
              minLength={8}
              placeholder="Enter 8 Characters or more"
              value={password}
              required
              className={styles.input}
              onChange={(e) => setpassword(e.target.value)}
            />
            <p className={styles.error}>{user.error}</p>
          </div>
          <span>
            <input type="checkbox" name="remember" />
            <label htmlFor="remember">Remember me</label>
          </span>

          <button
            disabled={user.loading ? true : false}
            style={
              user.loading ? { cursor: 'not-allowed' } : { cursor: 'pointer' }
            }
            type="submit"
          >
            {user.loading ? 'Submiting...' : 'Log In'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
