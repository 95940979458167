import React, { useContext, useEffect, useState } from 'react';
import styles from './AddCertificate.module.css';
import CertificateContext from '../../context/certificateContext';
import UserContext from '../../context/userContext';
import { useNavigate } from 'react-router-dom';

const initialState = {
  serial: 0,
  date: '',
  name: '',
  course: '',
  duration: '',
  fromDate: '',
  toDate: '',
};
const AddCertificate = () => {
  const [data, setdata] = useState(initialState);
  const { loading, message, error, createCertificate, clearState } =
    useContext(CertificateContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    for (const key in data) {
      if (data.hasOwnProperty.call(data, key)) {
        if (data[key].length < 1) return;
      }
    }
    createCertificate(data);
  };

  const handleChange = (e) => {
    if (e.target.name === 'serial') {
      e.target.value = parseInt(e.target.value, 10);
    }
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (message) {
      window.alert(message);
      setdata(initialState);
      clearState();
    }
    if (error) {
      window.alert(error);
      clearState();
    }
    if (user.isAuthenticated !== true) {
      navigate('/login');
    }
  }, [message, error, clearState, navigate, user.isAuthenticated]);

  return (
    <>
      <p className={styles.mobile_text}>Please open this page in Desktop</p>

      <div className={styles.main}>
        <div className={styles.container}>
          <h2>Add Certificate</h2>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div>
              <label htmlFor="serial" className={styles.label}>
                Serial No.
              </label>
              <input
                type="number"
                name="serial"
                placeholder="210XX..."
                value={data.serial}
                minLength={4}
                required
                className={styles.input}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/*  */}
            <div>
              <label htmlFor="name" className={styles.label}>
                Student Name
              </label>
              <input
                type="text"
                name="name"
                placeholder="eg... John Doe"
                value={data.name}
                minLength={4}
                maxLength={100}
                required
                className={styles.input}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* dob */}
            <div>
              <label htmlFor="date" className={styles.label}>
                Date of Birth
              </label>
              <input
                type="date"
                name="date"
                value={data.date}
                required
                className={styles.input}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/*  */}
            <div>
              <label htmlFor="course" className={styles.label}>
                Course Name
              </label>
              <input
                type="text"
                name="course"
                placeholder="Diploma in ..."
                value={data.course}
                maxLength={200}
                required
                className={styles.input}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/*  */}
            <div>
              <label htmlFor="duration" className={styles.label}>
                Course Duration
              </label>
              <input
                type="text"
                name="duration"
                placeholder="eg... 6 Months"
                value={data.duration}
                minLength={4}
                maxLength={50}
                required
                className={styles.input}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/*  */}
            <div>
              <label htmlFor="fromDate" className={styles.label}>
                Start Date
              </label>
              <input
                type="date"
                name="fromDate"
                value={data.fromDate}
                required
                className={styles.input}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/*  */}
            <div>
              <label htmlFor="toDate" className={styles.label}>
                Course End Date
              </label>
              <input
                type="date"
                name="toDate"
                value={data.toDate}
                required
                className={styles.input}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <button
              disabled={loading ? true : false}
              style={
                loading ? { cursor: 'not-allowed' } : { cursor: 'pointer' }
              }
              type="submit"
            >
              {loading ? 'Submiting...' : 'Create'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCertificate;
