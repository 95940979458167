import React from 'react';
import Style from './Home.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import {
  faEnvelope,
  faPhoneAlt,
  faQuoteLeft,
  faQuoteRight,
  faClock,
  faStamp,
  faUniversity,
} from '@fortawesome/free-solid-svg-icons';
import CardComponent from './CardComponent';
import CourseData from './CourseData';
import Pankaj from '../Images/pankaj.webp';
import Anuradha from '../Images/anuradha.webp';
import Ankush from '../Images/ankush.webp';
import Avatar from '../Images/Avatar.png';
import IsoImg from '../Images/iso.webp';
import IafImg from '../Images/iaf.webp';
import Fade from 'react-reveal/Fade';
const Home = () => {
  const scrolltoTop = () => {
    window.scrollTo({ top: 330 });
  };
  return (
    <div className={Style.HomeMain}>
      <div className={Style.UpperMain}>
        <div className={Style.CardMain}>
          {CourseData.map((data) => {
            return (
              <div key={data.id}>
                <CardComponent
                  title={data.title}
                  description={data.description}
                  image={data.image}
                  duration={data.duration}
                  fees={data.fees}
                  slug={data.slug}
                />
              </div>
            );
          })}
        </div>
        <Fade bottom>
          <div className={Style.Testimonial_main}>
            <div className={Style.Testimonial_title}>
              <h1>Testimonials</h1>
              <div>
                <hr />
                <FontAwesomeIcon
                  icon={faQuoteLeft}
                  size="2x"
                  color="rgb(251 205 56)"
                />
                <hr />
              </div>
            </div>
            <div className={Style.Testimonial_inner}>
              <div className={Style.Testimonial_upper}>
                <img src={Pankaj} alt="Testimonial" />
                <h1>Pankaj Dhawan</h1>
              </div>
              <div className={Style.Testimonial_desc}>
                <span className={Style.First_quote}>
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    size="2x"
                    color="rgb(255 25 59)"
                  />
                </span>
                <p>
                  Gandhi institution of education had truly improved my coding
                  skills & the mentors had such a great way of training. Unlike
                  other institutes, they focused more on practical training and
                  provided me with some high-quality content that helped me a
                  lot, even after completing the course. I will always be
                  grateful to them!
                </p>
                <span className={Style.Last_quote}>
                  <FontAwesomeIcon
                    icon={faQuoteRight}
                    size="2x"
                    color="rgb(255 25 59)"
                  />
                </span>
              </div>
            </div>
            {/* second testimonial */}
            <div className={Style.Testimonial_inner}>
              <div className={Style.Testimonial_upper}>
                <img src={Anuradha} alt="Testimonial" />
                <h1>Anuradha Rani</h1>
              </div>
              <div className={Style.Testimonial_desc}>
                <span className={Style.First_quote}>
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    size="2x"
                    color="rgb(255 25 59)"
                  />
                </span>
                <p>
                  I have taken training from this institution in Tally
                  Accounting and I am highly satisfied with their service. It
                  was a great institute and the faculties were excellent they
                  taught me in a very good and easy way. Their training method
                  is very simple and effective. If anybody wants to do these
                  courses, then I would recommend this institute.
                </p>
                <span className={Style.Last_quote}>
                  <FontAwesomeIcon
                    icon={faQuoteRight}
                    size="2x"
                    color="rgb(255 25 59)"
                  />
                </span>
              </div>
            </div>
            {/* third testimonial */}
            <div className={Style.Testimonial_inner}>
              <div className={Style.Testimonial_upper}>
                <img src={Avatar} alt="Testimonial" />
                <h1>Khushwinder Singh</h1>
              </div>
              <div className={Style.Testimonial_desc}>
                <span className={Style.First_quote}>
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    size="2x"
                    color="rgb(255 25 59)"
                  />
                </span>
                <p>
                  I had a very satisfying experience attending this AutoCAD
                  course. The instructor was very knowledgeable and well
                  prepared. He had time for all of the student's different
                  questions. The class was well organized and we went over many
                  different ways of optimizing AutoCAD to help us in our
                  different design careers.
                </p>
                <span className={Style.Last_quote}>
                  <FontAwesomeIcon
                    icon={faQuoteRight}
                    size="2x"
                    color="rgb(255 25 59)"
                  />
                </span>
              </div>
            </div>
            {/* fourth testimonial */}
            <div className={Style.Testimonial_inner}>
              <div className={Style.Testimonial_upper}>
                <img src={Ankush} alt="Testimonial" />
                <h1>Ankush Handa</h1>
              </div>
              <div className={Style.Testimonial_desc}>
                <span className={Style.First_quote}>
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    size="2x"
                    color="rgb(255 25 59)"
                  />
                </span>
                <p>
                  I did Web Development for my professional career growth ,
                  excellent center to study,great atmosphere ,peaceful place for
                  study. instructors performed well to teach a concept of our
                  need,staff are very close with students to clear the doubts
                  thanks Gandhi institution of education.
                </p>
                <span className={Style.Last_quote}>
                  <FontAwesomeIcon
                    icon={faQuoteRight}
                    size="2x"
                    color="rgb(255 25 59)"
                  />
                </span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={Style.Choose_us_main}>
            <div className={Style.Choose_us_inner}>
              <div className={Style.Choose_us_left}>
                <h1 className={Style.Choose_us_title}>WHY TO CHOOSE US?</h1>
                <div className={Style.Choose_us_left_inner}>
                  <span className={Style.Font_icon}>
                    <FontAwesomeIcon icon={faClock} size="3x" invert />
                  </span>
                  <span className={Style.Choose_us_text}>
                    <p>
                      <h3>Flexible Timmings</h3>At Gandhi Institute of
                      Education, a child's pace is respected. We do not follow
                      batch system. Every student is allowed to learn at their
                      own time.
                    </p>
                  </span>
                </div>
                <div className={Style.Choose_us_left_inner}>
                  <span className={Style.Font_icon}>
                    <FontAwesomeIcon icon={faStamp} size="3x" invert />
                  </span>
                  <span className={Style.Choose_us_text}>
                    <p>
                      <h3>Certified Courses</h3>Every course comes with an ISO
                      recognized certificate that can hold great value in the
                      professional world.
                    </p>
                  </span>
                </div>
                <div className={Style.Choose_us_left_inner}>
                  <span className={Style.Font_icon}>
                    <FontAwesomeIcon icon={faUniversity} size="3x" invert />
                  </span>
                  <span className={Style.Choose_us_text}>
                    <p>
                      <h3>Student Centered Learning</h3>At Gandhi Institute of
                      Education we make sure that the information provided is
                      based on concepts. We promote active learning in students
                      by giving them live projects to work.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <div className={Style.Affiliation_main}>
          <h1>Our Affiliations</h1>
          <div className={Style.Affiliation_img_cont}>
            <img
              src={IsoImg}
              alt="ISO Certified"
              className={Style.Affiliation_img_1}
            />

            <img
              src={IafImg}
              alt="IAF Certified"
              className={Style.Affiliation_img_2}
            />
          </div>
        </div>
        {/* maps  */}
      </div>
      <div className={Style.SideBar}>
        <div className={Style.InnerSideBar}>
          <h3>Our Courses :</h3>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/courses" exact="true">
              Diploma In Computer Applications(DCA)
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/tally-accounting-traning" exact="true">
              Tally Accounting Training
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/web-designing" exact="true">
              Web Designing
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/web-development" exact="true">
              Web Development
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/typing-english-punjabi" exact="true">
              Typing English & Punjabi
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/ms-office" exact="true">
              MS-Office
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/auto-cad" exact="true">
              Auto-CAD
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/computer-basics" exact="true">
              Computer Basics
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/computer-programming" exact="true">
              Computer Programming
            </Link>
          </div>
          <div className={Style.Courses_Link} onClick={scrolltoTop}>
            <Link to="/adobe-photoshop" exact="true">
              Adobe Photoshop
            </Link>
          </div>
          <div className={Style.AddressText}>
            <div>Opp. SBI Bank, Gagneja Street, </div>
            <div>Jalalabad(w) 152024 , Fazilka</div>
            <div>Punjab 144001</div>

            <div>
              <a href="tel:+919814353964" className={Style.Phone}>
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  size="1x"
                  color="rgb(0 0 238)"
                />
                +919814353964
              </a>
            </div>
            <div>
              <a
                href="mailto:gandhiinstitute7@gmail.com"
                className={Style.Mail}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="1x"
                  color="rgb(0 0 238)"
                />
                gandhiinstitute7@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
