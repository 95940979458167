import React from 'react';
import Style from './Courses.module.css';
import { Link } from 'react-router-dom';
import BookImg from '../Images/illustrations/book.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHistory,
  faRupeeSign,
  faDesktop,
  faPhotoVideo,
} from '@fortawesome/free-solid-svg-icons';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { BiNotepad } from 'react-icons/bi';
import ComputerImg from '../Images/illustrations/computerFundamental.webp';
import OfficeDeskImg from '../Images/illustrations/officeDesk.webp';
import NotepadImg from '../Images/illustrations/sheet.webp';
import WordpadImg from '../Images/illustrations/pages.webp';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import CourseData from './CourseData';
import { Fade } from 'react-reveal';

const Courses = () => {
  const scrolltoTop = () => {
    window.scrollTo({ top: 330 });
  };

  return (
    <div className={Style.Courses_main}>
      <div className={Style.Courses_sidebar}>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/courses"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Diploma In Computer Applications(DCA)
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/tally-accounting-traning"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Tally Accounting Training
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/web-designing"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Web Designing
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/web-development"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Web Development
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/typing-english-punjabi"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Typing English & Punjabi
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/ms-office"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            MS-Office
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/auto-cad"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Auto-CAD
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/computer-basics/"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Basics
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/adobe-photoshop"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Adobe Photoshop
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/computer-programming"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Programming
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/adca"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Advance Dimploma in Computer Application(ADCA)
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/cbtd"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Basic and Tally with Data Entry
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/pgdca"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            PGDCA
          </Link>
        </div>
      </div>
      <hr />
      <Bounce right>
        <div className={Style.Courses_right}>
          <div className={Style.Upper_main}>
            <div className={Style.Upper_main_left}>
              <h1>What is MS Office?</h1>
              <h2>
                MS Office is used to simplify basic office tasks and improve
                work productivity. Each application serves a unique purpose and
                offer specific tasks, such as MS Word is used for word
                processing, Excel for data management, PowerPoint for making
                presentations and Outlook for organizing emails and so on.
              </h2>

              <div className={Style.Contact_btn_main}>
                <a href="tel:+9815953964" className={Style.Anchor_tag}>
                  <span className={Style.Top_key}></span>
                  <span className={Style.Anchor_text}>Enquiry</span>
                  <span className={Style.Bottom_key_1}></span>
                  <span className={Style.Bottom_key_2}></span>
                </a>
              </div>
            </div>
            <div className={Style.Upper_main_right}>
              <img src={BookImg} alt="Diploma in Computer Application" />
            </div>
          </div>
          <div className={Style.Fees_Criteria}>
            <div className={Style.Criteria_table}>
              <div className={Style.Criteria_table_inner}>
                <h4>Course</h4>
                <p>MS OFFICE</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Fees</h4>
                <p>2599 /-</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Duration</h4>
                <p>3 Month</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Timing</h4>
                <p>Morning / Evening</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Topics</h4>
                <p>Computer Fundamental, Ms Office, Wordpad, Notepad</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Enquiry</h4>
                <p>+919815953964</p>
              </div>
            </div>
          </div>

          <h2 className={Style.Topic_title}>Topics Covered In This Course</h2>
          <div className={Style.Topic_main_cont}>
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faDesktop}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <div>
                  <h3>Computer Fundamentals</h3>
                  <p>
                    Before Shifting to advance computer knowledge it is highly
                    recommended to be aware of this topic.
                  </p>
                </div>
              </div>
            </div>
            {/* second topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faWindows}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <div>
                  <h3>MS Office</h3>
                  <p>
                    Microsoft Office is a suite of applications for office works
                    designed to help with productivity and completing common
                    tasks on a computer.
                  </p>
                </div>
              </div>
            </div>
            {/* third topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <BiNotepad size={45} style={{ fill: '#5777ba' }} />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Wordpad</h3>
                <p>
                  It can be used to create and modify documents. Although slower
                  to load than Notepad, it can handle graphics and rich
                  formatting, unlike notepad, along with handling larger files.
                </p>
              </div>
            </div>
            {/* fourth topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faPhotoVideo}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Notepad</h3>
                <p>
                  A small pad of paper, fastened along one edge, on which to jot
                  down notes, reminders, or other information. A pad of paper,
                  often bound, in which one jots down notes.
                </p>
              </div>
            </div>
          </div>
          {/* computer fundamentals */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={ComputerImg} alt="Computer Fundamentals" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Computer Fundamentals</h1>
                <p>
                  Computer science is an essential part of our daily life.
                  Almost everything around us are associated with computer
                  hardware and/or software. Invention in technology is directly
                  associated with the computer science. That is the reason to
                  study this subject. This course is generic in nature, anyone
                  from any discipline can opt this course to learn computer
                  basics. This course deals with fundamentals of computer. Which
                  includes generations of computer, evolution and development of
                  microprocessor, input and output devices, primary and
                  secondary storage devices, programming languages etc. It also
                  deals with the hardware and software aspects of the computer
                  like operating system, application software and system
                  software. It provides an overview of functions and working of
                  central processing unit, motherboard and other peripherals.
                </p>
              </div>
            </div>
          </Fade>
          {/* MS office */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Microsoft Office</h1>
                <p>
                  Microsoft Office (or simply Office) is a family of server
                  software, and services developed by Microsoft. It was first
                  announced by Bill Gates on August 1, 1988, in Las Vegas. The
                  first version of Office contained Microsoft Word, Microsoft
                  Excel, and Microsoft PowerPoint. Over the years, Office
                  applications have grown substantially closer with shared
                  features such as a common spell checker, data integration etc.
                  Office is produced in several versions targeted towards
                  different end-users and computing environments. The original,
                  and most widely used version, is the desktop version,
                  available for PCs running the Windows, Linux and Mac OS
                  operating systems. Office Online is a version of the software
                  that runs within a web browser, while Microsoft also maintains
                  Office apps for Android and iOS
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={OfficeDeskImg} alt="Microsoft Office" />
                </Slide>
              </div>
            </div>
          </Fade>
          {/* accounting package */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={NotepadImg} alt="Accounting Package" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Notepad</h1>
                <p>
                  Notepad is a text editor, i.e., an app specialized in editing
                  plain text. It can edit text files bearing the ".txt" filename
                  extension and compatible formats, such as batch files, INI
                  files, and log files. Notepad can read and write plain texts
                  encoded in ASCII, UTF-8, and UTF-16. It supports both
                  left-to-right and right-to-left based languages. Notepad
                  offers only the most basic text manipulation functions, such
                  as finding and replacing text. Until Windows ME, there were
                  almost no keyboard shortcuts and no line-counting feature.
                  Starting with Windows 2000, shortcuts for common commands like
                  "New", "Open", and "Save" were added, as well as a status bar
                  with a line counter available only when word-wrap is disabled.
                  Before Windows 10 version 1809, Notepad could not properly
                  interpret Unix-style or Mac-style newline characters. Windows
                  10 version 1809 also introduced the Ctrl +← Backspace keyboard
                  shortcut deletes the previous word, zoom functionality, the
                  ability to zoom in and out, and the "Search with Bing"
                  function. Improving performance has been the main focus of
                  Notepad's development. As part of this effort, Notepad is
                  capable of reading text files even when other apps have
                  acquired a range-based lock on the file.
                </p>
              </div>
            </div>
          </Fade>
          {/* Adobe photoshop*/}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Wordpad</h1>
                <p>
                  WordPad can format and print text, including font and bold,
                  italic, colored, and centered text, and lacks functions such
                  as a spell checker, thesaurus, and control of pagination. It
                  does not support footnotes and endnotes. WordPad can read,
                  render, and save many Rich Text Format (RTF) features that it
                  cannot create, such as tables, strikeout, superscript,
                  subscript, "extra" colors, text background colors, numbered
                  lists, right and left indentation, quasi-hypertext and URL
                  linking, and line-spacing greater than 1. Among its advantages
                  are low system-resource usage, simplicity, and speed
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={WordpadImg} alt="Adobe Photoshop Course" />
                </Slide>
              </div>
            </div>
          </Fade>

          {/* Relevant courses */}
          <h1 className={Style.Relevant_cont_title}>
            More Relevant Courses...
          </h1>
          <div className={Style.Relevant_courses_cont}>
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[0].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[0].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[0].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[0].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[0].description}
                </p>
                <button className={Style.Relevant_link}>
                  <Link
                    to="/tally-accounting-traning"
                    exact="true"
                    onClick={scrolltoTop}
                  >
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* second relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[1].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[1].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[1].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[1].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[1].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/adca" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* third relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[4].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[4].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[4].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[4].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[4].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/web-development" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* fourth relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[6].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[6].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[6].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[6].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[6].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/auto-cad" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
          </div>

          {/* Enspirational quotes  */}
          <div className={Style.Quotes_main_cont}>
            <div className={Style.Quote_card1}>
              <div className={Style.Quote_text1}>
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 330 307"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M302.258 176.221C320.678 176.221 329.889 185.432 329.889 203.853V278.764C329.889 297.185 320.678 306.395 302.258 306.395H231.031C212.61 306.395 203.399 297.185 203.399 278.764V203.853C203.399 160.871 207.902 123.415 216.908 91.4858C226.323 59.1472 244.539 30.902 271.556 6.75027C280.562 -1.02739 288.135 -2.05076 294.275 3.68014L321.906 29.4692C328.047 35.2001 326.614 42.1591 317.608 50.3461C303.69 62.6266 292.228 80.4334 283.223 103.766C274.626 126.69 270.328 150.842 270.328 176.221H302.258ZM99.629 176.221C118.05 176.221 127.26 185.432 127.26 203.853V278.764C127.26 297.185 118.05 306.395 99.629 306.395H28.402C9.98126 306.395 0.770874 297.185 0.770874 278.764V203.853C0.770874 160.871 5.27373 123.415 14.2794 91.4858C23.6945 59.1472 41.9106 30.902 68.9277 6.75027C77.9335 -1.02739 85.5064 -2.05076 91.6467 3.68014L119.278 29.4692C125.418 35.2001 123.985 42.1591 114.98 50.3461C101.062 62.6266 89.6 80.4334 80.5942 103.766C71.9979 126.69 67.6997 150.842 67.6997 176.221H99.629Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div className={Style.Quote_body_text1}>
                You can, you should, and if you are brave enough to start, you
                will.
              </div>
              <div className={Style.Quote_author1}>- by Stephen King</div>
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};

export default Courses;
