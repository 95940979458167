/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import Style from './ContactUs.module.css';
import LocatorImg from '../Images/illustrations/locator.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faPaperPlane,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';

const ContactUs = () => {
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [username, setUsername] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const disabled = () => {
      if (message.length < 1 || subject.length < 1 || username.length < 1) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    };
    disabled();
  }, [message, subject, username]);
  const handleSubmitForm = (e) => {
    e.preventDefault();
    window.open(
      `mailto:gandhiinstitute7@gmail.com?subject=${subject}&body=My name is ${username}. ${message}`
    );
  };
  return (
    <>
      <h1 className={Style.Contact_form_title}>Get In Touch With Us</h1>
      <form onSubmit={handleSubmitForm} className={Style.Contact_main_cont}>
        <div className={Style.Contact_form_left}>
          <img src={LocatorImg} alt="" />
        </div>
        <div className={Style.Contact_form_right}>
          <h3>Send us a message</h3>
          <input
            name="username"
            type="text"
            placeholder="your name"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            className={Style.Contact_username}
            required
            autoComplete="off"
          />
          <input
            type="text"
            name=""
            placeholder="subject"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            className={Style.Contact_subject}
            required
            autoComplete="off"
          />
          <input
            type="text"
            placeholder="message"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            className={Style.Contact_message}
            required
            autoComplete="off"
          />

          <button
            type="submit"
            className={Style.Contact_submit_btn}
            disabled={buttonDisabled}
          >
            Submit
          </button>
        </div>
      </form>
      <div className={Style.Contact_bottom_main}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3433.9387139962055!2d74.25328503701685!3d30.607491820367322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39177f05ce1df799%3A0xd0d0c3bd5cf06f81!2sGandhi%20Computers!5e0!3m2!1sen!2sin!4v1653540124597!5m2!1sen!2sin"
          className={Style.Google_map}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className={Style.Contact_bottom_right}>
          <h3 className={Style.Contact_form_left_title}>
            Let's talk about everything!
          </h3>
          <p>We're open for any suggestion or just to have a chat</p>

          <div>
            <div className={Style.Contact_form_address}>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                size="1x"
                color="rgb(0 0 0 )"
              />
              <p>
                <strong>Address: </strong> Opp. SBI Bank, Gagneja Street,
                Jalalabad(w) 152024 , Fazilka Punjab 144001
              </p>
            </div>
            <div className={Style.Contact_form_address}>
              <FontAwesomeIcon
                icon={faPhoneAlt}
                size="1x"
                color="rgb(0 0 0 )"
              />

              <p>
                <strong>Phone : </strong>
                <a href="tel:+919815953964">9815953964 , </a>
                <a href="tel:+919814353964">9814353964</a>
              </p>
            </div>
            <div className={Style.Contact_form_address}>
              <FontAwesomeIcon
                icon={faPaperPlane}
                size="1x"
                color="rgb(0 0 0 )"
              />
              <p>
                <strong>Mail : </strong>
                <a href="mailto:gandhiinstitute7@gmail.com">
                  gandhiinstitute7@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
