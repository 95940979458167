import React from 'react';

const Notfound = () => {
  return (
    <div
      style={{
        fontSize: '20px',
        textTransform: 'capitalize',
        color: 'var(--red)',
        width: '100vw',
        margin: '50px 0 ',
        textAlign: 'center',
      }}
    >
      We are sorry, This page does not exist or is under maintenance
    </div>
  );
};

export default Notfound;
