import { createContext, useState } from 'react';
import axios from 'axios';

const initialState = {
  loading: false,
  message: null,
  error: null,
};
const CertificateContext = createContext(initialState);

export function CertificateProvider({ children }) {
  const [certificate, setcertificate] = useState(initialState);
  const createCertificate = async (formData) => {
    try {
      setcertificate({ ...certificate, loading: true });
      let { data } = await axios.post('/api/v1/certificate', formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (data.success === true) {
        setcertificate({
          ...certificate,
          loading: false,
          message: data.message,
          error: null,
        });
      } else {
        throw new Error('Some error occured! Try again');
      }
    } catch (error) {
      console.log(error);
      setcertificate({
        ...certificate,
        message: null,
        error: error.response.data.message,
        loading: false,
      });
    }
  };

  const clearState = () => {
    setcertificate({ ...certificate, error: null, message: null });
  };
  const loading_true = () => {
    setcertificate({ ...certificate, loading: true });
  };
  const loading_false = () => {
    setcertificate({ ...certificate, loading: false });
  };

  return (
    <CertificateContext.Provider
      value={{
        loading: certificate.loading,
        message: certificate.message,
        error: certificate.error,
        createCertificate,
        clearState,
        loading_false,
        loading_true,
      }}
    >
      {children}
    </CertificateContext.Provider>
  );
}

export default CertificateContext;
