import React, { useState } from 'react';
import CertificateComponent from './CertificateComponent';
import './Certificate.css';
import axios from 'axios';
import CertificateTextFrame from '../Images/illustrations/certificate.svg';
import IsoImg from '../Images/iso.webp';

const Certificate = () => {
  const [dataInput, setDataInput] = useState({
    serial: '',
    date: '',
  });
  const [resData, setResData] = useState();
  const [errors, setErrors] = useState();
  const [showCertificate, setShowCertificate] = useState(false);
  const [resLoader, setResLoader] = useState(false);

  const handleInput = (event) => {
    const { name, value } = event.target;
    setDataInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    try {
      setResLoader(true);
      await axios
        .get(
          `/api/v1/certificate?serial=${dataInput.serial}&date=${dataInput.date}`
        )
        .then((response) => {
          const result = response.data;
          setResData(result);
          setErrors();
          setShowCertificate(true);
          setResLoader(false);
        });
    } catch (error) {
      error.response.data.message
        ? setErrors(error.response.data.message)
        : setErrors('Internal Server Error');
      setShowCertificate(false);
      setResLoader(false);
    }
  };

  return (
    <div className="certiificate_page_main">
      {!showCertificate && (
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="Certificate_page_form"
        >
          <h2 className="certificate_page_title">Certificate Verification</h2>
          <div className="input-group">
            <input
              type="number"
              name="serial"
              autoComplete="off"
              className="serial_no_input"
              onChange={handleInput}
              value={dataInput.serial}
              required
            />
            <label className="user-label">Serial Number</label>
          </div>
          <input
            className="Certificate_input_date"
            type="date"
            name="date"
            id=""
            onChange={handleInput}
            value={dataInput.date}
            required
          />
          <label htmlFor="date" className="inputdate_label">
            Enter Date of birth
          </label>
          <button type="submit" className="Form_submit_button">
            <div className="svg-wrapper-1">
              <div className="svg-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    fill="currentColor"
                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                  ></path>
                </svg>
              </div>
            </div>
            <span>Verify</span>
          </button>
          {errors && <div className="certificate_errors">{errors}</div>}
          {resLoader && (
            <div className="loader">
              <div className="scanner">
                <p>Verifing...</p>
              </div>
            </div>
          )}
        </form>
      )}

      {showCertificate && resData && (
        <div className="Certificate_cont_wrapper">
          <div id="Certificate_cont">
            <div className="Certificate_top_svg_wrapper">
              <img src={IsoImg} alt="" className="Iso_img" />
              <svg
                id="wave"
                viewBox="0 0 1440 160"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    id="sw-gradient-0"
                    x1="0"
                    x2="0"
                    y1="1"
                    y2="0"
                  >
                    <stop stopColor="rgba(85, 48, 36, 1)" offset="0%"></stop>
                    <stop
                      stopColor="rgba(141.481, 26.808, 26.808, 1)"
                      offset="100%"
                    ></stop>
                  </linearGradient>
                </defs>
                <path
                  fill="url(#sw-gradient-0)"
                  d="M0,64L80,74.7C160,85,320,107,480,96C640,85,800,43,960,21.3C1120,0,1280,0,1440,24C1600,48,1760,96,1920,120C2080,144,2240,144,2400,120C2560,96,2720,48,2880,29.3C3040,11,3200,21,3360,42.7C3520,64,3680,96,3840,112C4000,128,4160,128,4320,130.7C4480,133,4640,139,4800,125.3C4960,112,5120,80,5280,56C5440,32,5600,16,5760,24C5920,32,6080,64,6240,72C6400,80,6560,64,6720,69.3C6880,75,7040,101,7200,96C7360,91,7520,53,7680,34.7C7840,16,8000,16,8160,21.3C8320,27,8480,37,8640,50.7C8800,64,8960,80,9120,90.7C9280,101,9440,107,9600,112C9760,117,9920,123,10080,117.3C10240,112,10400,96,10560,82.7C10720,69,10880,59,11040,53.3C11200,48,11360,48,11440,48L11520,48L11520,160L11440,160C11360,160,11200,160,11040,160C10880,160,10720,160,10560,160C10400,160,10240,160,10080,160C9920,160,9760,160,9600,160C9440,160,9280,160,9120,160C8960,160,8800,160,8640,160C8480,160,8320,160,8160,160C8000,160,7840,160,7680,160C7520,160,7360,160,7200,160C7040,160,6880,160,6720,160C6560,160,6400,160,6240,160C6080,160,5920,160,5760,160C5600,160,5440,160,5280,160C5120,160,4960,160,4800,160C4640,160,4480,160,4320,160C4160,160,4000,160,3840,160C3680,160,3520,160,3360,160C3200,160,3040,160,2880,160C2720,160,2560,160,2400,160C2240,160,2080,160,1920,160C1760,160,1600,160,1440,160C1280,160,1120,160,960,160C800,160,640,160,480,160C320,160,160,160,80,160L0,160Z"
                ></path>
              </svg>
            </div>
            <div className="Certificate_title_cont">
              <span className="Certificate_title_cont_upper">
                <h1>Gandhi</h1>
                <h2>|</h2>
                <p>institute of education</p>
              </span>
              <div className="Certificate_title_cont_bottom">
                <h3>AN ISO 9001 : 2015 CERTIFIED COMPANY</h3>
                <h4>Certificate Number : VKIPPL221114Q2467</h4>
              </div>
            </div>
            <div className="CertificateTextFrame">
              <img src={CertificateTextFrame} alt="" />
              <p className="CertificateText">Certificate</p>
            </div>
            <div className="Certificate_bottom_main">
              <div className="Upper_content">
                <p> Sr. No :{resData.serial}</p>
              </div>
              <div className="Bottom_content">
                <p>
                  is awarded to
                  <strong style={{ textTransform: 'capitalize' }}>
                    {' '}
                    {resData.name}{' '}
                  </strong>{' '}
                  for passing the final examination of
                  <strong style={{ textTransform: 'capitalize' }}>
                    {' '}
                    {resData.course}{' '}
                  </strong>{' '}
                  in grade course attended from
                  <strong style={{ textTransform: 'capitalize' }}>
                    {' '}
                    {resData.fromDate}{' '}
                  </strong>{' '}
                  to
                  <strong style={{ textTransform: 'capitalize' }}>
                    {' '}
                    {resData.toDate}{' '}
                  </strong>
                  at Jalalabad (w)
                </p>
              </div>
            </div>
            <div className="Certificate_footer_wrapper">
              <div className="Certificate_footer">
                <div className="Certificate_footer_item1">
                  <p>
                    Gagneja Street, Near SBI Bank ,Jalalabad (w) 152024, Distt.
                    Fazilka, Punjab
                  </p>
                </div>
                <div className="Certificate_footer_item2">
                  <p>
                    Cell : +91-9815953964, +91-9814353964, Ph. 01638-514964,
                    Email: gandhiinstitute7@gmail.com
                  </p>
                </div>
              </div>
              <p className="Certificate_footer_grade">
                Grade scale:- A+ 80% above, A 70% - 79.9%, B+ 60% - 69.9%, B 50%
                - 59.9%, C+ 45% - 49.9%, C 40% - 44.9%
              </p>
            </div>

            {/* <h2>Duration :{resData.duration}</h2> */}
          </div>
        </div>
      )}
      <div className="download_button" style={{ display: 'none' }}>
        <CertificateComponent
          downloadFileName="Certificate"
          rootElementId="Certificate_cont"
          showButton={showCertificate}
        />
      </div>
    </div>
  );
};

export default Certificate;
