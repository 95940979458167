import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../myComponents/AdminNav.module.css';
import GcLogo from '../Images/new logo.webp';
import { useState } from 'react';
import UserContext from '../context/userContext';

const Sidebar = () => {
  const location = useLocation();
  const [hide, sethide] = useState(false);
  const { logout } = useContext(UserContext);

  useEffect(() => {
    if (location.pathname.includes('admin')) {
      sethide(false);
    } else {
      sethide(true);
    }
  }, [location]);

  return (
    <div style={{ display: hide && 'none' }} className={styles.main}>
      <div className={styles.container}>
        <div>
          <img
            src={GcLogo}
            className={styles.logo}
            alt="Gandhi computers logo"
          />
        </div>
        <div className={styles.center}>
          <Link to="/" className={styles.link}>
            Home
          </Link>
          <Link to="/admin" className={styles.link}>
            Certificates
          </Link>
          <Link to="/admin/add-certificate" className={styles.link}>
            Add-New
          </Link>
        </div>
        <div className={styles.right}>
          <button onClick={() => logout()}>Log Out</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
