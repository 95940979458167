import './App.css';
import Navbar from './myComponents/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeaderMain from './myComponents/HeaderMain';
import Footer from './myComponents/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Courses from './pages/Courses';
import Certificate from './myComponents/Certificate';
import CourseTally from './pages/CourseTally';
import ContactUs from './pages/ContactUs';
import CourseWebDesign from './pages/CourseWebDesign';
import CourseWebDevelopment from './pages/CourseWebDevelopment';
import Msoffice from './pages/Msoffice';
import Notfound from './pages/Notfound';
import Btep from './pages/Btep';
import Adca from './pages/Adca';
import Login from './pages/admin/Login';
import AdminHome from './pages/admin/AdminHome';
import ProtectedRoute from './middlewares/ProtectedRoute';
import { useContext, useEffect } from 'react';
import UserContext from './context/userContext';
import AdminNav from './myComponents/AdminNav';
import AddCertificate from './pages/admin/AddCertificate';
import CertificateContext from './context/certificateContext';
import Loader from './myComponents/Loader';

function App() {
  const { loaduser, user } = useContext(UserContext);
  const { loading: cert_loading } = useContext(CertificateContext);
  useEffect(() => {
    loaduser();
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        {user.isAuthenticated === true ? <AdminNav /> : null}
        <HeaderMain />
        {(user.loading || cert_loading) && <Loader />}
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/download-certificate" element={<Certificate />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/tally-accounting-traning" element={<CourseTally />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/web-designing" element={<CourseWebDesign />} />
          <Route path="/web-development" element={<CourseWebDevelopment />} />
          <Route path="/ms-office" element={<Msoffice />} />
          <Route path="/computer-basics" element={<Btep />} />
          <Route path="/adca" element={<Adca />} />

          {/* admin routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/admin/add-certificate" element={<AddCertificate />} />
          </Route>

          <Route element={<Notfound />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
