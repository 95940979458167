import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import CombineProviders from './context/combineProviders';

ReactDOM.render(
  <CombineProviders>
    <App />
  </CombineProviders>,
  document.getElementById('root')
);
