/* eslint-disable jsx-a11y/anchor-is-valid */
import Styles from './Navbar.module.css';
import { Link, useLocation } from 'react-router-dom';
import GcLogo from '../Images/new logo.webp';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../context/userContext';
const Navbar = () => {
  const [navToggle, setnavToggle] = useState(true);
  const [hide, sethide] = useState(false);
  const { user } = useContext(UserContext);
  const togglenav = () => {
    setnavToggle(!navToggle);
  };

  const location = useLocation();

  const scrollTop = () => {
    window.scrollTo({ top: 330 });
  };

  useEffect(() => {
    if (location.pathname.includes('admin')) {
      sethide(true);
    } else {
      sethide(false);
    }
  }, [location]);

  return (
    // <div className={`${Styles.Main} ${showNavbar && Styles.HideNav}`}>
    <div
      style={{ display: hide && 'none' }}
      className={`${Styles.Main} ${navToggle && Styles.Main_mobile}`}
    >
      <div className={Styles.Hamburger} onClick={togglenav}>
        <span className={Styles.Ham1}></span>
        <span className={Styles.Ham2}></span>
        <span className={Styles.Ham3}></span>
      </div>
      <div className={Styles.Left}>
        <img src={GcLogo} alt="Gandhi computers logo" />
      </div>
      <div className={Styles.Center}>
        <p>
          <Link
            to="/"
            exact="true"
            className={({ isActive }) =>
              isActive ? `${Styles.NavActive}` : ''
            }
            onClick={togglenav}
          >
            Home
          </Link>
        </p>

        <span onClick={scrollTop} className={Styles.Courses_main}>
          <Link
            to="/courses"
            className={({ isActive }) =>
              isActive ? `${Styles.NavActive}` : ''
            }
          >
            Courses
          </Link>
          <div className={Styles.Courses_dropdown}>
            <Link
              to="/courses"
              exact="true"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              Diploma In Computer Applications(DCA)
            </Link>
            <Link
              to="/tally-accounting-traning"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              Tally Accounting Training
            </Link>
            <Link
              to="/web-development"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              Web Designing & Development
            </Link>
            <Link
              to="/typing-english-punjabi"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              Typing English & Punjabi
            </Link>
            <Link
              to="/ms-office"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              MS-Office
            </Link>
            <Link
              to="/auto-cad"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              Auto-CAD
            </Link>
            <Link
              to="/computer-basics/"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              Computer Basics
            </Link>
            <Link
              to="/adobe-photoshop"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              Adobe Photoshop
            </Link>
            <Link
              to="/computer-programming"
              className={({ isActive }) => (isActive ? 'NavActive' : '')}
              onClick={togglenav}
            >
              Computer Programming
            </Link>
          </div>
        </span>

        <p onClick={scrollTop}>
          <Link
            to="/about"
            className={({ isActive }) =>
              isActive ? `${Styles.NavActive}` : ''
            }
            onClick={togglenav}
          >
            About
          </Link>
        </p>
        <span onClick={scrollTop} className={Styles.Contact_main}>
          <Link
            to="/contact"
            className={({ isActive }) =>
              isActive ? `${Styles.NavActive}` : ''
            }
            onClick={togglenav}
          >
            Contact
          </Link>
          <div className={Styles.Contact_dropdown}>
            <a href="tel:+9815953964" onClick={togglenav}>
              Call
            </a>
            <a href="mailto:gandhiinstitute7@gmail.com" onClick={togglenav}>
              Email
            </a>
          </div>
        </span>
      </div>
      <div className={Styles.Right}>
        <Link to="/download-certificate" onClick={togglenav}>
          <button
            className={Styles.Download_button}
            onClick={window.scrollTo({ top: 0 })}
          >
            <div className={Styles.Docs}>
              <svg
                className="css-i6dzq1"
                strokeLinejoin="round"
                strokeLinecap="round"
                fill="none"
                strokeWidth="2"
                stroke="currentColor"
                height="20"
                width="20"
                viewBox="0 0 24 24"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line y2="13" x2="8" y1="13" x1="16"></line>
                <line y2="17" x2="8" y1="17" x1="16"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
              Verify Certificate
            </div>
          </button>
        </Link>
        {user && user.isAuthenticated === true && (
          <Link to="/admin" onClick={togglenav}>
            <button
              style={{ marginLeft: '10px' }}
              className={Styles.Admin_button}
              onClick={window.scrollTo({ top: 0 })}
            >
              <div className={Styles.Icon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-user-circle"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  fill="none"
                  strokeWidth="2"
                  stroke="currentColor"
                  height="20"
                  width="20"
                  viewBox="0 0 24 24"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <circle cx="12" cy="10" r="3" />
                  <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                </svg>
                Account
              </div>
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Navbar;
