import React, { useEffect } from 'react';
import Styles from './HeaderMain.module.css';
import Desktop from '../Images/Desktop.webp';
import AnimatedText from 'react-animated-text-content';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const HeaderMain = () => {
  const [hide, sethide] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes('login') ||
      location.pathname.includes('admin')
    ) {
      sethide(true);
    } else {
      sethide(false);
    }
  }, [location]);

  return (
    <div className={hide ? Styles.hide : Styles.Header}>
      <div className={Styles.Left}>
        <div className={Styles.TitleText}>
          <AnimatedText
            type="chars"
            animation={{
              x: '200px',
              y: '-20px',
              scale: 1.1,
              ease: 'ease-in-out',
            }}
            animationType="bounce"
            interval={0.04}
            duration={0.6}
            tag=""
            className="animated-paragraph"
            includeWhiteSpaces
            threshold={0.1}
            rootMargin="20%"
          >
            Gandhi Institute Of Education
          </AnimatedText>
        </div>
        <div className={Styles.DescText}>
          <AnimatedText
            type="chars"
            animation={{
              x: '200px',
              y: '-20px',
              scale: 1.1,
              ease: 'ease-in-out',
            }}
            animationType="rifle"
            interval={0.05}
            duration={0.7}
            tag=""
            className="animated-paragraph"
            includeWhiteSpaces
            threshold={0.1}
            rootMargin="20%"
          >
            AN ISO 9001 : 2015 CERTIFIED COMPANY
          </AnimatedText>
        </div>
      </div>
      <div className={Styles.Right}>
        <img src={Desktop} alt="" className={Styles.DesktopImg} />
      </div>
    </div>
  );
};

export default HeaderMain;
