import React, { useState } from 'react';
import Styles from './About.module.css';
import ClassRoom2 from '../Images/Classroom2.webp';
import ClassRoom1 from '../Images/Classroom1.webp';
import PrincipalImg from '../Images/principal.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

// images
const CImages = [ClassRoom1, ClassRoom2];

const About = () => {
  const [classImage, setClassImage] = useState(CImages[0]);
  const [ClassText, setClassText] = useState(0);
  const HandlePrevImage = async () => {
    if (classImage === CImages[0]) {
      setClassImage(CImages[1]);

      setClassText(1);
    } else {
      await setClassImage(CImages[0]);
      setClassText(0);
    }
  };

  const HandleNextImage = () => {
    if (classImage === CImages[0]) {
      setClassImage(CImages[1]);
      setClassText(1);
    } else {
      setClassImage(CImages[0]);
      setClassText(0);
    }
  };

  return (
    <>
      <h1 className={Styles.About_header_title}>OVERVIEW</h1>
      <div className={Styles.About_header}>
        <div className={Styles.About_header_left}>
          <h1>From the principal's desk</h1>
          <p>
            Gandhi Institute Of Education is an institution that conducts
            training programs aimed at helping young career seekers understand
            the basic operational functioning of the sector and the job role
            they aspire to take on. Our uniquely designed programs empower young
            individuals to garner skills and knowledge for the modern global
            industry. We give 100 % Practical Training to our Students in every
            course with our long term Experience. Moreover with new techniques
            of Computer’s we try to keep update ourselves. Always Focused on
            Practical knowledge. Always Designed course according to the need of
            students.
          </p>
        </div>
        <div className={Styles.About_header_right}>
          <img src={PrincipalImg} alt="" />
        </div>
      </div>
      <div className={Styles.About_mission}>
        <h3>Our Mission</h3>
        <p>
          Our mission is to develop human resources to discover and disseminate
          computer knowledge to extend computer knowledge and its application
          beyond the boundaries of the Gandhi Institute is a Non Profit
          Voluntary Organization. It has been established for providing a right
          platform of quality education with affordable fee in computer and
          Information technology education. Inherent in this broad mission are
          extended education and public service designed to educate people and
          improve the human condition.
        </p>
      </div>

      <div className={Styles.ClassCont}>
        <img
          src={classImage}
          alt="computer classroom"
          className={Styles.ClassImg}
        />
        <div className={Styles.IconsCont}>
          <span className={Styles.FaLeft} onClick={HandlePrevImage}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="2x"
              inverse
              style={{ color: '#6d28d9' }}
            />
          </span>
          {ClassText === 0 && (
            <span className={Styles.IconsText}>
              <h1>Better Education For a Better World</h1>
              <p>We Believe there is nothing more important than Education.</p>
            </span>
          )}
          {ClassText === 1 && (
            <span className={Styles.IconsText}>
              <h1>Take the first step to knowledge with us</h1>
              <p>The purpose of education is to turn mirrors into windows.</p>
            </span>
          )}
          <span className={Styles.FaRight} onClick={HandleNextImage}>
            <FontAwesomeIcon
              icon={faChevronRight}
              size="2x"
              inverse
              style={{ color: '#6d28d9' }}
            />
          </span>
        </div>
      </div>
      <div className={Styles.Card_outer}>
        <div className={Styles.Card_main}>
          <div className={Styles.Card_item}>
            <h3>Experienced Faculties</h3>
            <p>
              Our faculty pool comprises of industry experienced professional,
              having a strong passion for training youngsters to make them
              employable .
            </p>
          </div>
          <div className={Styles.Card_item}>
            <h3> Unique Methodology</h3>
            <p>
              Our methodology of training delivers the knowledge and skills to
              our students through a multimodal approach comprising of
              assignments, case studies, project, and industry seminars.
            </p>
          </div>
          <div className={Styles.Card_item}>
            <h3> Recognised Certifications</h3>
            <p>
              Successful students not only earn the Keerti certification but
              also have the opportunity to earn industry-recognized
              certifications from Tally and Microsoft.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
