const CourseData = [
  {
    id: '1',
    title: 'Accountancy',
    description:
      'This course and the relevant training will prepare the studen to be able to carry Accounting and Taxation tasks in all three sectors such as Manufacturing, Trading and Service Industries',
    image: require('../Images/accounts.webp'),
    duration: '6 Months',
    fees: '5999/-',
    slug: 'tally-accounting-traning',
  },

  {
    id: '2',
    title: 'A-DCA',
    description:
      'DCA, Diploma in Computer Applications, is a one-year diploma course in the field of Computer Applications which involves the study of numerous computer applications such as MS Office, Internet Applications, Operating System, Programming Languages.',
    image: require('../Images/adca.webp'),
    duration: '1 Year',
    fees: '16999/-',
    slug: 'adca',
  },
  {
    id: '3',
    title: 'DCA',
    description:
      'DCA, Diploma in Computer Applications, is a one-year diploma course in the field of Computer Applications which involves the study of numerous computer applications such as MS Office, Internet Applications, Operating System, Programming Languages.',
    image: require('../Images/dca.webp'),
    duration: '1 Year',
    fees: '12999/-',
    slug: 'courses',
  },
  {
    id: '4',
    title: 'Web Designing',
    description:
      'Web Designing refers to designing a website that is displayed on the Internet. It is the front-end work done on the website.  We conduct Web Designing to establish a quality user experience.',
    image: require('../Images/web-designing.webp'),
    duration: '4 Months',
    fees: '5999/-',
    slug: 'web-designing',
  },
  {
    id: '5',
    title: 'Web Development',
    description:
      "Web developers create and maintain websites. They are also responsible for the site's technical aspects, such as its performance and capacity, which are measures of a website's speed and how much traffic the site can handle.",
    image: require('../Images/web-development.webp'),
    duration: '1 Year',
    fees: '11999/-',
    slug: 'web-development',
  },
  {
    id: '6',
    title: 'Basic and English Punjabi Typing (BTEP)',
    description:
      'In this course students will be taught MS-office, english & punjabi typing and the relevant training will prepare the studen to be able to applicable for clerikal , data entry operator posts',
    image: require('../Images/typing.webp'),
    duration: '1 / 3 / 6 Months',
    fees: '999 / 2599 / 5999/-',
    slug: 'computer-basics',
  },
  {
    id: '7',
    title: 'Auto-CAD',
    description:
      'AutoCAD is a 3D computer-assisted design software by Autodesk that is created for manufacturing planning, product designing, building designing, construction, and civil engineering.',
    image: require('../Images/auto-cad.webp'),
    duration: '1 / 6 Months',
    fees: '1299 / 7299/-',
    slug: 'auto-cad',
  },
  {
    id: '8',
    title: 'Adobe Photoshop',
    description:
      'Adobe Photoshop is the predominant photo editing and manipulation software on the market. Its uses range from the full-featured editing of large batches of photos to creating intricate digital paintings and drawings that mimic those done by hand.',
    image: require('../Images/adobe.webp'),
    duration: '1 / 3 Months',
    fees: '1299 / 3599/-',
    slug: 'adobe-photoshop',
  },
];
export default CourseData;
