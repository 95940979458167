import React, { useContext, useEffect, useState } from 'react';
import styles from './AdminHome.module.css';
import CertificateContext from '../../context/certificateContext';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../../context/userContext';

const AdminHome = () => {
  const [certificates, setcertificates] = useState([]);
  const { loading, loading_true, loading_false } =
    useContext(CertificateContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const getCertificates = async () => {
    try {
      loading_true();
      let { data } = await axios.get('/api/v1/certificates');
      if (data.success === true) {
        console.log(data);
        setcertificates(data.certificates);
      } else {
        throw new Error('Some error occured! Try again');
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        window.alert(error.response.data.message);
      }
    } finally {
      loading_false();
    }
  };

  const handleDelete = async (_id, name) => {
    if (!_id) return;
    let confirm = window.confirm(
      `Delete certificate of  - ${name.toUpperCase()}`
    );
    if (!confirm) return;
    try {
      loading_true();
      let { data } = await axios.delete(`/api/v1/certificate/${_id}`);
      if (data.success === true) {
        getCertificates();
        window.alert(data.message);
      } else {
        throw new Error('Some error occured! Try again');
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        window.alert(error.response.data.message);
      }
    } finally {
      loading_false();
    }
  };

  useEffect(() => {
    getCertificates();
  }, []);
  useEffect(() => {
    if (user.isAuthenticated !== true) {
      navigate('/login');
    }
  }, [navigate, user.isAuthenticated]);

  return (
    <>
      <p className={styles.mobile_text}>Please open this page in Desktop</p>

      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.table_wrapper}>
            <div className={styles.upper_content}>
              <h2>Available Certificates</h2>
              <Link to="/admin/add-certificate">
                <button
                  style={{ marginLeft: '10px' }}
                  onClick={window.scrollTo({ top: 0 })}
                >
                  <div className={styles.Icon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-user-circle"
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      fill="none"
                      strokeWidth="2"
                      stroke="currentColor"
                      height="20"
                      width="20"
                      viewBox="0 0 24 24"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="9" y1="12" x2="15" y2="12" />
                      <line x1="12" y1="9" x2="12" y2="15" />
                      <path d="M4 6v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1m-5 0h-2m-5 0h-1a1 1 0 0 1 -1 -1v-1m0 -5v-2m0 -5" />
                    </svg>
                    Add New
                  </div>
                </button>
              </Link>
            </div>
            {certificates.length !== 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Student Name</th>
                    <th>DOB</th>
                    <th>Course Name</th>
                    <th>Duration</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Del.</th>
                  </tr>
                </thead>
                <tbody>
                  {certificates.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.serial}</td>
                        <td>{item.name}</td>
                        <td>{item.date.substr(0, 10)}</td>
                        <td style={{ whiteSpace: 'normal' }}>{item.course}</td>
                        <td>{item.duration}</td>
                        <td>{item.fromDate.substr(0, 10)}</td>
                        <td>{item.toDate.substr(0, 10)}</td>
                        <td
                          className={styles.del}
                          onClick={() => handleDelete(item._id, item.name)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-trash"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#6d28d9"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="4" y1="7" x2="20" y2="7" />
                            <line x1="10" y1="11" x2="10" y2="17" />
                            <line x1="14" y1="11" x2="14" y2="17" />
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                          </svg>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className={styles.warn_text}>
                {loading === false ? 'Opps! No certificates available' : ''}
              </p>
            )}
          </div>{' '}
        </div>
      </div>
    </>
  );
};

export default AdminHome;
