import React from 'react';
import Style from './Courses.module.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHistory,
  faRupeeSign,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import { MdAccountTree, MdOutlineInventory2 } from 'react-icons/md';
import { SiMicrosoftexcel } from 'react-icons/si';
import { BiRupee } from 'react-icons/bi';

import TallyErp from '../Images/illustrations/tablet-person.webp';
import AccountsImg from '../Images/illustrations/accounts.webp';
import ExcelImg from '../Images/illustrations/excel.webp';
import TaxiationImg from '../Images/illustrations/taxiation.webp';
import GstImg from '../Images/illustrations/gst.webp';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import CourseData from './CourseData';
import { Fade } from 'react-reveal';

const CourseTally = () => {
  const scrolltoTop = () => {
    window.scrollTo({ top: 330 });
  };

  return (
    <div className={Style.Courses_main}>
      <div className={Style.Courses_sidebar}>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/courses"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Diploma In Computer Applications(DCA)
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/tally-accounting-traning"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Tally Accounting Training
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/web-designing"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Web Designing
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/web-development"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Web Development
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/typing-english-punjabi"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Typing English & Punjabi
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/ms-office"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            MS-Office
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/auto-cad"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Auto-CAD
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/computer-basics/"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Basics
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/adobe-photoshop"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Adobe Photoshop
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/computer-programming"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Programming
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/adca"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Advance Dimploma in Computer Application(ADCA)
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/cbtd"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Basic and Tally with Data Entry
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/pgdca"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            PGDCA
          </Link>
        </div>
      </div>
      <hr />
      <Bounce right>
        <div className={Style.Courses_right}>
          <div className={Style.Upper_main}>
            <div className={Style.Upper_main_left}>
              <h1>What is Tally Accounting?</h1>
              <h2>
                Looking for a job in accounting taxation or payroll? We will
                suggest a course that makes your career bright in accounting.
                Tally is one such course in Accounting that can help you climb
                your career ladder at a faster pace. Gandhi Institute Of
                Education Provide Best Tally Accounting Training, this is of 3
                Months and 6 Months.
              </h2>

              <div className={Style.Contact_btn_main}>
                <a href="tel:+9815953964" className={Style.Anchor_tag}>
                  <span className={Style.Top_key}></span>
                  <span className={Style.Anchor_text}>Enquiry</span>
                  <span className={Style.Bottom_key_1}></span>
                  <span className={Style.Bottom_key_2}></span>
                </a>
              </div>
            </div>
            <div className={Style.Upper_main_right}>
              <img src={AccountsImg} alt="Tally Accounting Package" />
            </div>
          </div>
          <div className={Style.Fees_Criteria}>
            <div className={Style.Criteria_table}>
              <div className={Style.Criteria_table_inner}>
                <h4>Course</h4>
                <p>Accounts</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Fees</h4>
                <p>999 /-, 4999 /-</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Duration</h4>
                <p>1 Month, 3 Months</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Timing</h4>
                <p>Morning / Evening</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Topics</h4>
                <p>Tally ERP.9, Inventory, Advance Excel, Taxiation, GST</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Enquiry</h4>
                <p>+919815953964</p>
              </div>
            </div>
          </div>

          <h2 className={Style.Topic_title}>Topics Covered In This Course</h2>
          <div className={Style.Topic_main_cont}>
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <MdAccountTree size={45} style={{ fill: '#5777ba' }} />
              </div>
              <div className={Style.Topic_main_right}>
                <div>
                  <h3>Tally ERP.9</h3>
                  <p>
                    What is ERP? ERP refers to 'Enterprise Resource Planning'
                    software which integrates various functions of a business
                    into a single unified system.
                  </p>
                </div>
              </div>
            </div>
            {/* second topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <MdOutlineInventory2 size={45} style={{ fill: '#5777ba' }} />
              </div>
              <div className={Style.Topic_main_right}>
                <div>
                  <h3>Inventory</h3>
                  <p>
                    Inventory represents the goods your small business has for
                    sale or in storage.
                  </p>
                </div>
              </div>
            </div>
            {/* third topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <SiMicrosoftexcel size={45} style={{ fill: '#5777ba' }} />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Advance Excel</h3>
                <p>
                  Microsoft Excel is a spreadsheet program created by Microsoft
                  that uses tables to record and analyze numerical and
                  statistical data with formulas and functions
                </p>
              </div>
            </div>
            {/* fourth topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Taxiation</h3>
                <p>
                  Taxation is the imposition of compulsory levies on individuals
                  or entities by governments in almost every country of the
                  world.
                </p>
              </div>
            </div>
            {/* fifth topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <BiRupee size={45} style={{ fill: '#5777ba' }} />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>GST</h3>
                <p>
                  The Goods and Services Tax (GST) is a value-added tax levied
                  on most goods and services sold for domestic consumption.
                </p>
              </div>
            </div>
          </div>
          {/* computer fundamentals */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={TallyErp} alt="Tally ERP.9" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Tally ERP 9</h1>
                <p>
                  Tally is an ERP accounting software package used for recording
                  day to day business data of a company. The latest version of
                  Tally is Tally ERP 9.Tally ERP 9 Software is one acclaimed
                  financial accounting system and inventory management system
                  with power computer. It is one best accounting software that
                  can be intergrated with other bussiness applications such as
                  sales, finance, purchasing, payroll, etc. In this software you
                  can create and maintain accounts up to 99,999 companies.
                </p>
              </div>
            </div>
          </Fade>
          {/* MS office */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Inventory</h1>
                <p>
                  Inventory software refers to business applications that are
                  designed to track and manage product sales, material
                  purchases, delivery of goods and other common production
                  processes. Inventory software helps businesses bring in the
                  materials they need to produce a finished product for
                  customers. Inventory software programs often use bar codes or
                  other identifier tracking systems to give a complete
                  accounting of the shipping and storage segments of the
                  production process.
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={AccountsImg} alt="Accounts" />
                </Slide>
              </div>
            </div>
          </Fade>
          {/* accounting package */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={ExcelImg} alt="Accounting Package" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Advance Excel</h1>
                <p>
                  MS Excel, also known as Microsoft Excel, is a widely used
                  software in most companies or businesses worldwide. It
                  provides various tools, functions, and features to make this
                  software profitable for global companies in various fields.
                  Therefore, to learn and properly organize data using Excel, we
                  must know the definition and basics of this powerful
                  software.Microsoft Excel is a spreadsheet program created by
                  Microsoft that uses tables to record and analyze numerical and
                  statistical data with formulas and functions
                </p>
              </div>
            </div>
          </Fade>
          {/* Adobe photoshop*/}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Taxiation</h1>
                <p>
                  Taxation is a term for when a taxing authority, usually a
                  government, levies or imposes a financial obligation on its
                  citizens or residents. Paying taxes to governments or
                  officials has been a mainstay of civilization since ancient
                  times. The term taxation applies to all types of involuntary
                  levies, from income to capital gains to estate taxes. Though
                  taxation can be a noun or verb, it is usually referred to as
                  an act, the resulting revenue is usually called taxes.
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={TaxiationImg} alt="Adobe Photoshop Course" />
                </Slide>
              </div>
            </div>
          </Fade>

          {/* C, C++ programming */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={GstImg} alt="C and C++ Programming Course" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>GST</h1>
                <p>
                  Goods and Services Tax GST is an indirect tax or consumption
                  tax used in India on the supply of goods and services. It is a
                  comprehensive, multistage, destination-based tax:
                  comprehensive because it has subsumed almost all the indirect
                  taxes except a few state taxes. Multi-staged as it is, the GST
                  is imposed at every step in the production process, but is
                  meant to be refunded to all parties in the various stages of
                  production other than the final consumer and as a
                  destination-based tax, it is collected from point of
                  consumption and not point of origin like previous taxes.
                </p>
              </div>
            </div>
          </Fade>

          {/* Relevant courses */}
          <h1 className={Style.Relevant_cont_title}>
            More Relevant Courses...
          </h1>
          <div className={Style.Relevant_courses_cont}>
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[7].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[7].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[7].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[7].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[7].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/adobe-photoshop" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* second relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[1].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[1].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[1].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[1].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[1].description}
                </p>
                <button className={Style.Relevant_link}>
                  <Link to="/adca" exact="true" onClick={scrolltoTop}>
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* third relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[3].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[3].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[3].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[3].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[3].description}
                </p>
                <button className={Style.Relevant_link}>
                  <Link to="/web-designing" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* fourth relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[6].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[6].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[6].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[6].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[6].description}
                </p>
                <button className={Style.Relevant_link}>
                  <Link to="/auto-cad" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
          </div>

          {/* Enspirational quotes  */}
          <div className={Style.Quotes_main_cont}>
            <div className={Style.Quote_card1}>
              <div className={Style.Quote_text1}>
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 330 307"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M302.258 176.221C320.678 176.221 329.889 185.432 329.889 203.853V278.764C329.889 297.185 320.678 306.395 302.258 306.395H231.031C212.61 306.395 203.399 297.185 203.399 278.764V203.853C203.399 160.871 207.902 123.415 216.908 91.4858C226.323 59.1472 244.539 30.902 271.556 6.75027C280.562 -1.02739 288.135 -2.05076 294.275 3.68014L321.906 29.4692C328.047 35.2001 326.614 42.1591 317.608 50.3461C303.69 62.6266 292.228 80.4334 283.223 103.766C274.626 126.69 270.328 150.842 270.328 176.221H302.258ZM99.629 176.221C118.05 176.221 127.26 185.432 127.26 203.853V278.764C127.26 297.185 118.05 306.395 99.629 306.395H28.402C9.98126 306.395 0.770874 297.185 0.770874 278.764V203.853C0.770874 160.871 5.27373 123.415 14.2794 91.4858C23.6945 59.1472 41.9106 30.902 68.9277 6.75027C77.9335 -1.02739 85.5064 -2.05076 91.6467 3.68014L119.278 29.4692C125.418 35.2001 123.985 42.1591 114.98 50.3461C101.062 62.6266 89.6 80.4334 80.5942 103.766C71.9979 126.69 67.6997 150.842 67.6997 176.221H99.629Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div className={Style.Quote_body_text1}>
                {' '}
                You can, you should, and if you are brave enough to start, you
                will.
              </div>
              <div className={Style.Quote_author1}>- by Stephen King</div>
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};

export default CourseTally;
