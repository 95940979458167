import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import UserContext from '../context/userContext';
const ProtectedRoute = () => {
  const { user } = useContext(UserContext);
  if (!user || user.isAuthenticated !== true) {
    return Navigate({ to: '/login', replace: true });
  }
  return user.loading === false && <Outlet />;
};

export default ProtectedRoute;
