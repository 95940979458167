import { createContext, useState } from 'react';
import axios from 'axios';

const initialState = {
  isAuthenticated: false,
  loading: false,
  user: null,
  error: null,
};
const UserContext = createContext(initialState);

export function UserProvider({ children }) {
  const [user, setuser] = useState(initialState);

  const login = async ({ email, password }) => {
    try {
      setuser({ ...user, loading: true });
      let { data } = await axios.post(
        '/api/v1/login',
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (data.success === true) {
        setuser({
          ...user,
          user: data.user,
          isAuthenticated: true,
          loading: false,
        });
      } else {
        throw new Error('Please Login to access this record');
      }
    } catch (error) {
      setuser({
        ...user,
        user: null,
        error: error.response.data.message,
        isAuthenticated: false,
        loading: false,
      });
    }
  };
  const logout = async () => {
    try {
      setuser({ ...user, loading: true });
      let { data } = await axios.get('/api/v1/logout');
      if (data.success === true) {
        setuser({
          ...user,
          user: null,
          isAuthenticated: false,
          loading: false,
          error: null,
        });
      } else {
        throw new Error('SOme error occoured! Tr again');
      }
    } catch (error) {
      setuser({
        ...user,
        error: error.response.data.message,
        loading: false,
      });
    }
  };
  const loaduser = async () => {
    try {
      setuser({ ...user, loading: true });
      let { data } = await axios.get('/api/v1/me');
      if (data.success === true) {
        setuser({
          ...user,
          user: data.user,
          isAuthenticated: true,
          loading: false,
          error: null,
        });
      } else {
        throw new Error('Please Login to access this record');
      }
    } catch (error) {
      setuser({
        ...user,
        user: null,
        isAuthenticated: false,
        loading: false,
      });
    }
  };

  return (
    <UserContext.Provider value={{ user, login, loaduser, logout }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
