import React from 'react';
import Style from './Courses.module.css';
import { Link } from 'react-router-dom';
import BookImg from '../Images/illustrations/book.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHistory,
  faRupeeSign,
  faDesktop,
  faFileInvoiceDollar,
  faPhotoVideo,
  faCode,
  faNetworkWired,
} from '@fortawesome/free-solid-svg-icons';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import ComputerImg from '../Images/illustrations/computerFundamental.webp';
import OfficeDeskImg from '../Images/illustrations/officeDesk.webp';
import AccountsImg from '../Images/illustrations/accounts.webp';
import AdobeImg from '../Images/illustrations/adobe.webp';
import PersonalityImg from '../Images/illustrations/personality.webp';
import CppImg from '../Images/illustrations/cpp.webp';
import InternetImg from '../Images/illustrations/internet.webp';
import WindowsImg from '../Images/illustrations/windows.webp';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import CourseData from './CourseData';
import { Fade } from 'react-reveal';

const Adca = () => {
  const scrolltoTop = () => {
    window.scrollTo({ top: 330 });
  };

  return (
    <div className={Style.Courses_main}>
      <div className={Style.Courses_sidebar}>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/courses"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Diploma In Computer Applications(DCA)
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/tally-accounting-traning"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Tally Accounting Training
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/web-designing"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Web Designing
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/web-development"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Web Development
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/typing-english-punjabi"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Typing English & Punjabi
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/ms-office"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            MS-Office
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/auto-cad"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Auto-CAD
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/computer-basics/"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Basics
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/adobe-photoshop"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Adobe Photoshop
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/computer-programming"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Programming
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/adca"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Advance Dimploma in Computer Application(ADCA)
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/cbtd"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            Computer Basic and Tally with Data Entry
          </Link>
        </div>
        <div className={Style.Courses_Link} onClick={scrolltoTop}>
          <Link
            to="/pgdca"
            exact="true"
            className={({ isActive }) => (isActive ? `${Style.Active}` : '')}
          >
            PGDCA
          </Link>
        </div>
      </div>
      <hr />
      <Bounce right>
        <div className={Style.Courses_right}>
          <div className={Style.Upper_main}>
            <div className={Style.Upper_main_left}>
              <h1>What is ADCA?</h1>
              <h2>
                Gandhi Institute Of Education Provide Best ADCA, Advance Diploma
                In Computer Application Course, is a one-year diploma course in
                the field of Computer Applications which involves the study of
                numerous computer applications such as MS Office, Internet
                Applications, Operating System,Web Designing And Financial
                Accounting.
              </h2>

              <div className={Style.Contact_btn_main}>
                <a href="tel:+9815953964" className={Style.Anchor_tag}>
                  <span className={Style.Top_key}></span>
                  <span className={Style.Anchor_text}>Enquiry</span>
                  <span className={Style.Bottom_key_1}></span>
                  <span className={Style.Bottom_key_2}></span>
                </a>
              </div>
            </div>
            <div className={Style.Upper_main_right}>
              <img src={BookImg} alt="Diploma in Computer Application" />
            </div>
          </div>
          <div className={Style.Fees_Criteria}>
            <div className={Style.Criteria_table}>
              <div className={Style.Criteria_table_inner}>
                <h4>Course</h4>
                <p>ADCA</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Fees</h4>
                <p>16999 /-</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Duration</h4>
                <p>1 Year</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Timing</h4>
                <p>Morning / Evening</p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Topics</h4>
                <p>
                  Computer Fundamental, MS Office, Accounts, Web Designing
                  Personality Development, Internet, Operaring System
                </p>
              </div>
              <div className={Style.Criteria_table_inner}>
                <h4>Enquiry</h4>
                <p>+919815953964</p>
              </div>
            </div>
          </div>

          <h2 className={Style.Topic_title}>Topics Covered In This Course</h2>
          <div className={Style.Topic_main_cont}>
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faDesktop}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <div>
                  <h3>Computer Fundamentals</h3>
                  <p>
                    Before Shifting to advance computer knowledge it is highly
                    recommended to be aware of this topic.
                  </p>
                </div>
              </div>
            </div>
            {/* second topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faWindows}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <div>
                  <h3>MS Office</h3>
                  <p>
                    Microsoft Office is a suite of applications for office works
                    designed to help with productivity and completing common
                    tasks on a computer.
                  </p>
                </div>
              </div>
            </div>
            {/* third topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Accounting Package</h3>
                <p>
                  The advantages of a computerized system include faster and
                  more efficient record-keeping, access to real-time financial
                  data, automated invoicing and cost savings.
                </p>
              </div>
            </div>
            {/* fourth topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faPhotoVideo}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Web Designing</h3>
                <p>
                  Web design refers to the design of websites that are displayed
                  on the internet. It usually refers to the user experience
                  aspects of website development rather than software
                  development.
                </p>
              </div>
            </div>

            {/* sixth topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faCode}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Personality Development</h3>
                <p>
                  It refers to the process by which the organized thought and
                  behavior patterns that make up a person's unique personality
                  emerge over time.
                </p>
              </div>
            </div>
            {/* seventh topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faNetworkWired}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Internet & Multimedia</h3>
                <p>
                  It refers to the process by which the organized thought and
                  behavior patterns that make up a person's unique personality
                  emerge over time.
                </p>
              </div>
            </div>
            {/* eight topic */}
            <div className={Style.Topic_main}>
              <div className={Style.Topic_main_left}>
                <FontAwesomeIcon
                  icon={faWindows}
                  size="3x"
                  color="rgb(87, 119, 186)"
                />
              </div>
              <div className={Style.Topic_main_right}>
                <h3>Windows</h3>
                <p>
                  A window is a separate viewing area on a computer display
                  screen in a system that allows multiple viewing areas as part
                  of a graphical user interface ( GUI ).
                </p>
              </div>
            </div>
          </div>
          {/* computer fundamentals */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={ComputerImg} alt="Computer Fundamentals" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Computer Fundamentals</h1>
                <p>
                  Computer science is an essential part of our daily life.
                  Almost everything around us are associated with computer
                  hardware and/or software. Invention in technology is directly
                  associated with the computer science. That is the reason to
                  study this subject. This course is generic in nature, anyone
                  from any discipline can opt this course to learn computer
                  basics. This course deals with fundamentals of computer. Which
                  includes generations of computer, evolution and development of
                  microprocessor, input and output devices, primary and
                  secondary storage devices, programming languages etc. It also
                  deals with the hardware and software aspects of the computer
                  like operating system, application software and system
                  software. It provides an overview of functions and working of
                  central processing unit, motherboard and other peripherals.
                </p>
              </div>
            </div>
          </Fade>
          {/* MS office */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Microsoft Office</h1>
                <p>
                  Microsoft Office (or simply Office) is a family of server
                  software, and services developed by Microsoft. It was first
                  announced by Bill Gates on August 1, 1988, in Las Vegas. The
                  first version of Office contained Microsoft Word, Microsoft
                  Excel, and Microsoft PowerPoint. Over the years, Office
                  applications have grown substantially closer with shared
                  features such as a common spell checker, data integration etc.
                  Office is produced in several versions targeted towards
                  different end-users and computing environments. The original,
                  and most widely used version, is the desktop version,
                  available for PCs running the Windows, Linux and Mac OS
                  operating systems. Office Online is a version of the software
                  that runs within a web browser, while Microsoft also maintains
                  Office apps for Android and iOS
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={OfficeDeskImg} alt="Microsoft Office" />
                </Slide>
              </div>
            </div>
          </Fade>
          {/* accounting package */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={AccountsImg} alt="Accounting Package" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Accounting Package</h1>
                <p>
                  Accounting software is a computer program that maintains
                  account books on computers, including recording transactions
                  and account balances. Depending on the purpose, the software
                  can manage budgets, perform accounting tasks for multiple
                  currencies, perform payroll and customer relationship
                  management, and prepare financial reporting. The first
                  accounting software was introduced in 1978. Since then, the
                  accounting software has revolutionized from supporting basic
                  accounting operations to performing real-time accounting and
                  supporting financial processing and reporting.
                </p>
              </div>
            </div>
          </Fade>
          {/* Adobe photoshop*/}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Web Designing</h1>
                <p>
                  Web design is a Web development process for creating a website
                  that focuses on aesthetic factors like layout, user interface
                  and other visual imagery in order to make the website more
                  visually appealing and easy to use. Web design makes use of
                  various programs and tools in order to achieve the intended
                  look, such as Dreamweaver, Photoshop and many others. In order
                  to create a winning design, Web designers need to think about
                  their audience, the purpose of the website and the visual
                  appeal of the design.
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={AdobeImg} alt="Adobe Photoshop Course" />
                </Slide>
              </div>
            </div>
          </Fade>

          {/* C, C++ programming */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={CppImg} alt="C and C++ Programming Course" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>JavaScript Programming</h1>
                <p>
                  JavaScript is a client-side scripting language, which means
                  the source code is processed by the client's web browser
                  rather than on the web server. This means JavaScript functions
                  can run after a webpage has loaded without communicating with
                  the server. For example, a JavaScript function may check a web
                  form before it is submitted to make sure all the required
                  fields have been filled out. The JavaScript code can produce
                  an error message before any information is actually
                  transmitted to the server. Like server-side scripting
                  languages, such as PHP and ASP, JavaScript code can be
                  inserted anywhere within the HTML of a webpage. However, only
                  the output of server-side code is displayed in the HTML, while
                  JavaScript code remains fully visible in the source of the
                  webpage. It can also be referenced in a separate .JS file,
                  which may also be viewed in a browser.
                </p>
              </div>
            </div>
          </Fade>
          {/* personality development*/}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Personality Development</h1>
                <p>
                  Personality development helps you gain recognition and
                  acceptance from the society as well as people around.
                  Personality development plays an essential role not only in an
                  individual's professional but also personal lives. It makes an
                  individual disciplined, punctual and an asset for his/her
                  organization.
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={PersonalityImg} alt="Personality Development" />
                </Slide>
              </div>
            </div>
          </Fade>
          {/* internet and multimedia */}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_img_cont}>
                <Slide left>
                  <img src={InternetImg} alt="Internet Course" />
                </Slide>
              </div>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Internet & Multimedia</h1>
                <p>
                  Work from home, collaboration, and access to a global
                  workforce. The Internet offers people benefit to connect with
                  others around the world. There are various online services
                  that help you to communicate and work with other people all
                  over the world. Also, it can make services and producing new
                  products faster.
                  <ul>
                    <li>Sending EMails</li>
                    <li>Online form Filling</li>
                    <li>Downloading usefull assets from Internet</li>
                    <li>Paying Bills</li>
                  </ul>
                </p>
              </div>
            </div>
          </Fade>
          {/* Internet*/}
          <Fade bottom>
            <div className={Style.Breif_main_cont}>
              <div className={Style.Brief_description_cont}>
                <h1 className={Style.Brief_title}>Internet & Multimedia</h1>
                <p>
                  In this relevant course and training you will learn to use
                  Windows to browse the Internet, check your email, edit digital
                  photos, listen to music, play games, and do much more. Windows
                  is also used in many offices because it gives you access to
                  productivity tools such as calendars, word processors, and
                  spreadsheets.A window is a separate viewing area on a computer
                  display screen in a system that allows multiple viewing areas
                  as part of a graphical user interface ( GUI ). Windows are
                  managed by a windows manager as part of a windowing system . A
                  window can usually be resized by the user.
                </p>
              </div>
              <div className={Style.Brief_img_cont}>
                <Slide right>
                  <img src={WindowsImg} alt="Microsoft Windows Course" />
                </Slide>
              </div>
            </div>
          </Fade>

          {/* Relevant courses */}
          <h1 className={Style.Relevant_cont_title}>
            More Relevant Courses...
          </h1>
          <div className={Style.Relevant_courses_cont}>
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[0].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[0].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[0].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[0].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[0].description}
                </p>
                <button className={Style.Relevant_link}>
                  <Link
                    to="/tally-accounting-traning"
                    exact="true"
                    onClick={scrolltoTop}
                  >
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* second relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[2].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[2].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[2].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[2].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[2].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/adca" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* third relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[4].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[4].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[4].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[4].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[4].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/web-development" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
            {/* fourth relevant course card */}
            <div className={Style.Relevant_box}>
              <img
                src={CourseData[6].image}
                alt="Tally Accounting course"
                className={Style.Relevant_img}
              />
              <div className={Style.Relevant_bottom}>
                <h2>{CourseData[6].title} </h2>
                <div className={Style.Relevant_icons}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[6].duration}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faRupeeSign}
                      size="1x"
                      color="rgb(255 25 59)"
                    />
                    {CourseData[6].fees}
                  </div>
                </div>
                <p className={Style.Relevant_description}>
                  {CourseData[6].description}
                </p>
                <button className={Style.Relevant_link} onClick={scrolltoTop}>
                  <Link to="/auto-cad" exact="true">
                    Read More...
                  </Link>
                </button>
              </div>
            </div>
          </div>

          {/* Enspirational quotes  */}
          <div className={Style.Quotes_main_cont}>
            <div className={Style.Quote_card1}>
              <div className={Style.Quote_text1}>
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 330 307"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M302.258 176.221C320.678 176.221 329.889 185.432 329.889 203.853V278.764C329.889 297.185 320.678 306.395 302.258 306.395H231.031C212.61 306.395 203.399 297.185 203.399 278.764V203.853C203.399 160.871 207.902 123.415 216.908 91.4858C226.323 59.1472 244.539 30.902 271.556 6.75027C280.562 -1.02739 288.135 -2.05076 294.275 3.68014L321.906 29.4692C328.047 35.2001 326.614 42.1591 317.608 50.3461C303.69 62.6266 292.228 80.4334 283.223 103.766C274.626 126.69 270.328 150.842 270.328 176.221H302.258ZM99.629 176.221C118.05 176.221 127.26 185.432 127.26 203.853V278.764C127.26 297.185 118.05 306.395 99.629 306.395H28.402C9.98126 306.395 0.770874 297.185 0.770874 278.764V203.853C0.770874 160.871 5.27373 123.415 14.2794 91.4858C23.6945 59.1472 41.9106 30.902 68.9277 6.75027C77.9335 -1.02739 85.5064 -2.05076 91.6467 3.68014L119.278 29.4692C125.418 35.2001 123.985 42.1591 114.98 50.3461C101.062 62.6266 89.6 80.4334 80.5942 103.766C71.9979 126.69 67.6997 150.842 67.6997 176.221H99.629Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div className={Style.Quote_body_text1}>
                {' '}
                You can, you should, and if you are brave enough to start, you
                will.
              </div>
              <div className={Style.Quote_author1}>- by Stephen King</div>
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};

export default Adca;
